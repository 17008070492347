// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0

import React, { useEffect } from 'react';
import * as config from '../../config';
import nolive from '../../assets/nolive.png';

// Styles
import './VideoPlayer.css';

const VideoPlayer = () => {
  const maxMetaData = 10;

  useEffect(() => {
    let metaData = []
    const mediaPlayerScriptLoaded = () => {
  
      const MediaPlayerPackage = window.IVSPlayer;
      
      if (!MediaPlayerPackage.isPlayerSupported) {
          console.warn("The current browser does not support the Amazon IVS player.");
          return;
      }
  
      const PlayerState = MediaPlayerPackage.PlayerState;
      const PlayerEventType = MediaPlayerPackage.PlayerEventType;

      const player = MediaPlayerPackage.create();
      player.attachHTMLVideoElement(document.getElementById("video-player"));

      player.addEventListener(PlayerState.PLAYING, () => {
          console.log("Player State - PLAYING");
      });
      player.addEventListener(PlayerState.ENDED, () => {
          console.log("Player State - ENDED");
      });
      player.addEventListener(PlayerState.READY, () => {
          console.log("Player State - READY");
      });
      player.addEventListener(PlayerEventType.ERROR, (err) => {
          console.warn("Player Event - ERROR:", err);
      });
      player.addEventListener(PlayerEventType.TEXT_METADATA_CUE, (cue) => {
          console.log('Timed metadata: ', cue.text);
          const metadataText = JSON.parse(cue.text);
          const productId = metadataText['productId'];
          const metadataTime = player.getPosition().toFixed(2);
  
          // only keep max 5 metadata records
          if (metaData.length > maxMetaData) {
            metaData.length = maxMetaData;
          }
          // insert new metadata
          metaData.unshift(`productId: ${productId} (${metadataTime}s)`);
      });

      player.setAutoplay(true);
      player.load(config.PLAYBACK_URL);
      player.setVolume(0.5);
    }
    const mediaPlayerScript = document.createElement("script");
    mediaPlayerScript.src = "https://player.live-video.net/1.4.0/amazon-ivs-player.min.js";
    mediaPlayerScript.async = true;
    mediaPlayerScript.onload = () => mediaPlayerScriptLoaded();
    document.body.appendChild(mediaPlayerScript);
  }, []);
  
  return (
    <div className="player-wrapper">
      <div className="aspect-169 pos-relative full-width full-height">
        <video id="video-player" className="video-elem pos-absolute full-width" poster={nolive} playsInline controls></video>
      </div>
    </div>
  )
}

export default VideoPlayer;
